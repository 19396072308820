import {formatUrl} from '@teemill/common/helpers';
import {http} from '@teemill/common/services';
import {LiteProduct} from '@teemill/common/models';

import {
  NotFoundError,
  PermissionDeniedError,
  ServerError,
} from '@teemill/common/errors';

export class ProductProvider {
  public static list(
    divisionId: number,
    limit: number,
    sort?: string | null,
    algorithm?: string | null,
    similarProductUrlName?: string | null
  ): Promise<LiteProduct[]> {
    return http
      .get(formatUrl(`/omnis/v3/division/${divisionId}/products/`), {
        params: {
          limit,
          sort,
          algorithm,
          similarTo: similarProductUrlName,
        },
      })
      .success((data: LiteProduct[]) => data)
      .throw(404, () => new NotFoundError())
      .throw(403, () => new PermissionDeniedError())
      .throw('5xx', () => new ServerError())
      .output();
  }

  public static getLite(
    divisionId: number,
    productUrlName: string
  ): Promise<LiteProduct> {
    return http
      .get(
        formatUrl(
          `/omnis/v3/division/${divisionId}/products/${productUrlName}/`
        ),
        {
          params: {
            lite: true,
          },
        }
      )
      .success((data: LiteProduct) => data)
      .throw(404, () => new NotFoundError())
      .throw(403, () => new PermissionDeniedError())
      .throw('5xx', () => new ServerError())
      .output();
  }
}
